import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import { returnEmptyIfNull } from "src/utils/utils";
import StripContentList from "../StripContentList/StripContentList";

const PostListingDetailed = ({ twoColumns, className, postEdges }) => {
	const getPostList = () => {
		const postList = [];
		postEdges.forEach(postEdge => {
			postList.push({
				path: postEdge.node.fields.slug,
				tags: postEdge.node.frontmatter.tags,
				userJourney: returnEmptyIfNull(
					postEdge.node.frontmatter.userJourney
				),
				h1title: postEdge.node.frontmatter.h1title,
				date: postEdge.node.fields.date,
				description: returnEmptyIfNull(
					postEdge.node.frontmatter.description
				)
			});
		});
		return postList;
	};

	const postList = getPostList();

	return (
		<StripContentList
			twoColumns={twoColumns}
			className={className}
			postList={postList}
		/>
	);
};

PostListingDetailed.propTypes = {
	postEdges: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default PostListingDetailed;
