import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import styles from "../StripContentList.module.scss";

const StripContentRow = ({ h1title, className, path, description, date }) => {
	const stripRow = (
		<div key={h1title} className={`${className} postBlock p-t-lg`}>
			<Link to={path} className="hoverLink">
				<h3 className="m-b-sm">{h1title}</h3>
			</Link>
			<p className="noPMargin">{description}</p>
			<div className="m-t-sm">
				<span className={`${styles.greyTag}`}>{date}</span>
			</div>
			<Link to={path}>
				<div className="m-t-sm">Read more</div>
			</Link>
		</div>
	);
	return stripRow;
};

StripContentRow.propTypes = {
	h1title: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	date: PropTypes.string.isRequired
};

export default StripContentRow;
