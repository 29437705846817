import React from "react";
import PropTypes from "prop-types";

import StripContentRow from "./StripContentRow/StripContentRow";
import StripContentResourceRow from "./StripContentResourceRow/StripContentResourceRow";

const StripContentList = ({ twoColumns, className, postList, rowType }) => {
	let twoColumnsClass = "";
	if (twoColumns) {
		twoColumnsClass = "twoColumns";
	}
	let renderedList;
	if (rowType === "post") {
		renderedList = postList.map(post => (
			<StripContentRow
				h1title={post.h1title}
				path={post.path}
				description={post.description}
				date={post.date}
			/>
		));
	} else if (rowType === "resource") {
		renderedList = postList.map(post => (
			<StripContentResourceRow
				h1title={post.h1title}
				path={post.path}
				highlighted={post.highlighted}
				description={post.description}
				linkArray={post.linkArray}
			/>
		));
	}
	return (
		<div className={`${className} ${twoColumnsClass}`}>{renderedList}</div>
	);
};

StripContentList.propTypes = {
	postList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	twoColumns: PropTypes.bool,
	className: PropTypes.string,
	rowType: PropTypes.string
};

StripContentList.defaultProps = {
	twoColumns: false,
	className: "",
	rowType: "post"
};

export default StripContentList;
