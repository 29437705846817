import React from "react";
import { Link } from "gatsby";
import { graphql, useStaticQuery } from "gatsby";

const TableOfContents = ({ slug }) => {
	const data = useStaticQuery(graphql`
		query {
			allMdx {
				nodes {
					tableOfContents
					frontmatter {
						slug
					}
				}
			}
		}
	`);

	let post;
	data.allMdx.nodes.forEach(e => {
		if (e.frontmatter.slug === slug) {
			post = e;
		}
	});

	function renderItem(item) {
		return (
			<li key={item.title}>
				<Link to={item.url}>{item.title}</Link>
				{renderSubItems(item)}
			</li>
		);
	}

	function renderSubItems(item) {
		let output;
		if (item.items && item.items.length) {
			output = <ul>{item.items.map(renderItem)}</ul>;
		}
		return output;
	}

	return (
		<div className="toc">
			<ul>{post?.tableOfContents.items.map(renderItem)}</ul>
		</div>
	);

	// return (
	// 	<h1> TOC </h1>
	// )
};

// {
/* <div className="toc">
			<ul>
				{post?.tableOfContents.items.map(item => (
					<li key={item.title}>
						<Link to={item.url}>{item.title}</Link>
					</li>
				))}
			</ul>
		</div> */
// ?}

export default TableOfContents;
