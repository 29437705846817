import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import styles from "../StripContentList.module.scss";

const isRelativeUrl = url => {
	return !/^(https?:\/\/|\/\/)/i.test(url);
};
const StripContentResourceRow = ({
	h1title,
	className,
	path,
	description,
	linkArray,
	highlighted
}) => {
	const highlightedClass = highlighted ? styles.highlightedStrip : "";
	const highlightedText = highlighted
		? "This is the resource you last selected."
		: "";

	const handleLinkClick = (event, url) => {
		localStorage.removeItem("resourceHighlight");
	};

	const additionalLinks = linkArray.map((link, index) =>
		isRelativeUrl(link.url) ? (
			<Link
				to={link.url}
				key={index}
				className={`${styles.hoverLink} button is-outlined buttonSecondary`}
				onClick={e => handleLinkClick()} // Added onClick here
			>
				{link.anchor}
			</Link>
		) : (
			<a
				href={link.url}
				target="_blank"
				rel="noopener noreferrer"
				key={index}
				className={`${styles.hoverLink} button buttonSecondary`}
				onClick={e => handleLinkClick()} // Added onClick here
			>
				{link.anchor}
			</a>
		)
	);

	const stripRow = (
		<div
			key={h1title}
			className={`${styles.stripContentRow} ${highlightedClass} ${className} postBlock m-b-md`}
		>
			<div className={styles.titleDescriptionContainer}>
				<h3 className={`${styles.h1title} m-r-m`}>{h1title}</h3>
				<div className={styles.description}>
					<span>{highlightedText}</span>
					{description}
				</div>
			</div>
			<div className={styles.linkContainer}>{additionalLinks}</div>
		</div>
	);
	return stripRow;
};

StripContentResourceRow.propTypes = {
	h1title: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	linkArray: PropTypes.arrayOf(
		PropTypes.shape({
			url: PropTypes.string.isRequired,
			anchor: PropTypes.string.isRequired
		})
	).isRequired,
	onClick: PropTypes.func // Optional prop for onClick function
};

export default StripContentResourceRow;
