import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import { Follow } from "react-twitter-widgets";
import bioHead from "static/images/pages/face.jpg";

import styles from "./Bio.module.scss";

const Bio = ({ config, expanded }) => {
	// const data = useStaticQuery(graphql`
	// 	query {
	// 		fileName: file(relativePath: { eq: "images/pages/face.jpg" }) {
	// 			childImageSharp {
	// 				fixed(width: 80, height: 80) {
	// 					...GatsbyImageSharpFixed
	// 				}
	// 			}
	// 		}
	// 	}
	// `);

	return (
		<div className={styles.bioContainer}>
			{/* <Img
				className={styles.avatar}
				fixed={bioHead}
				alt={config.userName}
				fadeIn={false}
			/> */}
			<div className={`${styles.bioImgStyles} ${styles.avatar}`}>
				<img
					src={bioHead}
					alt="Dom Woodman Headshot"
					width="80"
					height="80"
				></img>
			</div>

			<span>
				<strong>By Dominic Woodman</strong> -- I&apos;m the founder of
				Piped Out and have never loved 3rd person bio&apos;s.
				<div className="m-t-sm">
					<Follow
						username={config.userTwitter}
						options={{ count: expanded ? true : "none" }}
					/>
				</div>
			</span>
		</div>
	);
};

export default Bio;
