import React from "react";

const OutboundLink = ({ href, children, className, ...props }) => {
	const handleClick = event => {
		if (props["data-post-submit"]) {
			localStorage.setItem(
				"resourceHighlight",
				props["data-post-submit"]
			);
		}
	};

	return (
		<a
			className={className}
			href={href}
			target="_blank"
			rel="noopener noreferrer"
			onClick={handleClick}
			{...props}
		>
			{children}
		</a>
	);
};

export default OutboundLink;
