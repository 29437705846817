import React from "react";
import { Link } from "gatsby";

import styles from "./EmailSignupForm.module.scss";

const EmailSignupForm = ({ slug }) => {
	return <div className={`_form_1 ${styles.emailBox}`}></div>;
};

export default EmailSignupForm;
