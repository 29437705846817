import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

// Local imports
import Layout from "src/layouts/LayoutWhiteHeader";
import PostListingDetailed from "src/components/generic/PostListingDetailed/PostListingDetailed";
import config from "data/SiteConfig";

export const userJourneyBlurb = {
	learn_seo: {
		prettyTitle: "Learn SEO",
		description: (
			<React.Fragment>
				<p>These are all articles about SEO.</p>
				<p>
					They range from beginners to advanced, but I am trying to
					make them more beginner friendly with footnotes.
				</p>
			</React.Fragment>
		)
	},
	working_with_data: {
		prettyTitle: "Learn how to work with data",
		description: (
			<React.Fragment>
				<p>These are all articles about working with data.</p>
				<p>
					If you work in the digital world, there&#39;s a good chance
					being better with data will help you.
				</p>
			</React.Fragment>
		)
	},
	tools: {
		prettyTitle: "Tools",
		description: (
			<React.Fragment>
				<p>
					I make a lot of little tools & scripts (as well as the
					occasional bigger one).
				</p>
				<p>You can find them all here!</p>
			</React.Fragment>
		)
	},
	work_skills: {
		prettyTitle: "Soft Skills",
		description: (
			<React.Fragment>
				<p>
					I don&#39;t like calling them soft skills because I feel
					like it undermines how useful they are. Work skills?
				</p>
				<p>
					Whatever we call them, these are all posts about how to get
					better at your job, which aren&#39;t related to the specific
					skills.
				</p>
			</React.Fragment>
		)
	},
	web_basics: {
		prettyTitle: "How do websites work?",
		description: (
			<React.Fragment>
				<p>This is a series of posts/videos about how websites work.</p>
				<p>
					We work with websites every day, but few people I think
					actually have the knowledge about what is going on behind it
					all.
				</p>
				<p>So let&#39;s learn!</p>
			</React.Fragment>
		)
	},
	web_dev: {
		prettyTitle: "Web Development",
		description: (
			<React.Fragment>
				<p>Thoughts on web development.</p>
			</React.Fragment>
		)
	},
	search_console: {
		prettyTitle: "Search Console",
		description: (
			<React.Fragment>
				<p>Everything you wanted to know about search console.</p>
			</React.Fragment>
		)
	},
	technical: {
		prettyTitle: "Technical SEO",
		description: (
			<React.Fragment>
				<p>Working on technical SEO.</p>
			</React.Fragment>
		)
	},
	ga4: {
		prettyTitle: "GA4",
		description: (
			<React.Fragment>
				<p>
					Getting the GA4 basics handled and then move onto the
					complex!
				</p>
			</React.Fragment>
		)
	}
};

const UserJourneyTemplate = ({ data, pageContext }) => {
	return (
		<Layout>
			<main>
				<Helmet
					title={`${
						userJourneyBlurb[pageContext.userJourney].prettyTitle
					} - ${config.siteTitle}`}
				/>
				<div className="greyBar p-t-lg p-b-lg">
					<div className="container">
						<h1 className="is-size-3">
							Category:{" "}
							{
								userJourneyBlurb[pageContext.userJourney]
									.prettyTitle
							}
						</h1>
						{userJourneyBlurb[pageContext.userJourney].description}
					</div>
				</div>
				<div className={`m-b-xl container`}>
					<PostListingDetailed
						postEdges={data.allMdx.edges}
						twoColumns={true}
					/>
				</div>
			</main>
		</Layout>
	);
};

UserJourneyTemplate.propTypes = {
	data: PropTypes.shape()
};

export default UserJourneyTemplate;

/* eslint no-undef: "off" */
/**
 * Here we get to select the variables out of page context.
 */
export const pageQuery = graphql`
	query UserJourneyPage($userJourney: String) {
		allMdx(
			limit: 1000
			sort: { fields: [fields___date], order: DESC }
			filter: { frontmatter: { userJourney: { in: [$userJourney] } } }
		) {
			totalCount
			edges {
				node {
					fields {
						slug
						date(formatString: "MMMM DD, YYYY")
					}
					frontmatter {
						h1title
						date
						description
						userJourney
					}
				}
			}
		}
	}
`;
