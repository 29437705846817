import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
// import { Disqus } from "gatsby-plugin-disqus";

// Local imports
import Layout from "src/layouts/LayoutWhiteHeader";
import Bio from "src/components/generic/Bio/Bio";
import PostTags from "src/components/generic/PostTags/PostTags";
import PostUserJourney from "src/components/generic/PostUserJourney/PostUserJourney";
import SocialLinks from "src/components/generic/SocialLinks/SocialLinks";
import SEO from "src/components/generic/SEO/SEO";
import config from "data/SiteConfig";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import TableOfContents from "src/components/mdx/TableOfContents/TableOfContents";
import EmailSignupForm from "src/components/mdx/EmailSignupForm/EmailSignupForm";
import OutboundLink from "src/components/mdx/OutboundLink/OutboundLink";
// Style imports
import styles from "src/templates/Post/post.module.scss";
import "src/templates/Post/prism-okaidia.css";

export default ({ data, pageContext }) => {
	const {
		slug,
		nexttitle,
		nextslug,
		prevtitle,
		prevslug,
		noindex
	} = pageContext;
	const postNode = data.mdx;
	const post = postNode.frontmatter;
	const { date } = postNode.fields;

	console.log(noindex);
	if (!post.id) {
		post.id = slug;
	}

	let postMetaRendered;
	// if (!post.standalone) {
	// 	postMetaRendered = (
	// 		<p className={styles.postMeta}>
	// 			{date} &mdash; {postNode.timeToRead} Min Read{" "}
	// 		</p>
	// 	);
	// }

	const liveSiteUrl = `${config.siteUrl}${slug}`;
	const disqusConfig = {
		url: liveSiteUrl
	};

	let socialAndBio;
	if (!post.standalone) {
		socialAndBio = (
			<div className="m-b-lg">
				<Bio config={config} />
				<div className={styles.postMeta}>
					<SocialLinks postPath={slug} postNode={postNode} />
				</div>
				{/* <h2 className="m-b-lg">Comments</h2> */}
				{/* <Disqus config={disqusConfig} /> */}
			</div>
		);
	}

	let userJourneyRendered;
	if (post.userJourney && post.userJourney !== "blank") {
		userJourneyRendered = (
			<PostUserJourney userJourney={post.userJourney} />
		);
	}

	const shortcodes = {
		TableOfContents,
		EmailSignupForm,
		OutboundLink
	};

	function addIdToHeaders(str) {
		const regex = /mdx\(\"(h[1-6])\", (null|.*?), \"(.+?)\"/g;
		let match;
		// eslint-disable-next-line no-cond-assign
		while ((match = regex.exec(str)) !== null) {
			const [, header, secondArg, text] = match;
			if (secondArg === "null") {
				const id = text
					.toLowerCase()
					.replace(/[^\w\s-]+/g, "") // ?,'... to nothing
					.replace(/[\s\t\n]+/g, "-"); // spaces to -
				const replacement = `mdx(\"${header}\", {\n id: \"${id}\"\n },\"${text}\"`;
				str = str.replace(match[0], replacement);
			}
		}
		return str;
	}

	const modifiedContent = addIdToHeaders(postNode.body);

	return (
		<Layout>
			<main className={`${styles.postHero} container`}>
				<SEO
					postPath={slug}
					postNode={postNode}
					useEmailScript={true}
					noindex={noindex}
					postSEO
				/>
				<section className="hero is-bold">
					<div className={`hero-body ${styles.heroBodyOverride}`}>
						<div
							className={`container ${styles.containerModifier}`}
						>
							<div className={styles.contentWidth}>
								<h1 className="title">{post.h1title}</h1>
								{postMetaRendered}
								<div className={styles.postMeta}>
									{userJourneyRendered}
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className={`${styles.postBody}`}>
					<div className={`container ${styles.containerModifier}`}>
						<div className={styles.contentWidth}>
							{/* <MDXProvider components={shortcodes}>
								<MDXRenderer>{node.code.body}</MDXRenderer>
							</MDXProvider> */}
							{/* <MDXProvider components={shortcodes}> */}
							{/* <TableOfContents slug={"diy-unit-testing-for-seo"} /> */}

							<MDXProvider components={shortcodes}>
								<MDXRenderer>{modifiedContent}</MDXRenderer>
							</MDXProvider>

							{/* <div
								// eslint-disable-next-line react/no-danger
								dangerouslySetInnerHTML={{
									__html: postNode.html
								}}
							/> */}
							{socialAndBio}
						</div>
					</div>
				</section>
			</main>
		</Layout>
	);
};

//
// code {
// 	body
// }
/* eslint no-undef: "off" */
export const pageQuery = graphql`
	query BlogPostBySlug($slug: String!) {
		mdx(fields: { slug: { eq: $slug } }) {
			tableOfContents
			body
			frontmatter {
				title
				date
				h1title
				social_image
				categories
				tags
				userJourney
				standalone
			}
			fields {
				slug
				date(formatString: "MMMM DD, YYYY")
			}
		}
	}
`;
